import React, { useState } from 'react';
import { useQuery, gql } from "@apollo/client";
import Helmet from 'react-helmet';
import AddCapture from './AddCapture';
import AddPokemon from './AddPokemon';
import ListPokemon from './ListPokemon';
import { Link } from "react-router-dom";

//import { ReactSession } from 'react-client-session';

const SESSION_QUERY = gql`
    {
        getSession {
            token
        }
    }
`;

function Sidebar(props) {

    const [filter_owned,        setFilterOwned]       = useState(true);
    const [filter_not_owned,    setFilterNotOwned]    = useState(true);

    const [filter_generation_1, setFilterGeneration1] = useState(true);
    const [filter_generation_2, setFilterGeneration2] = useState(true);
    const [filter_generation_3, setFilterGeneration3] = useState(true);
    const [filter_generation_4, setFilterGeneration4] = useState(true);
    const [filter_generation_5, setFilterGeneration5] = useState(true);
    const [filter_generation_6, setFilterGeneration6] = useState(true);
    const [filter_generation_7, setFilterGeneration7] = useState(true);
    const [filter_generation_8, setFilterGeneration8] = useState(true);

    const [filter_type_bug,      setFilterTypeBug]      = useState(true);
    const [filter_type_dark,     setFilterTypeDark]     = useState(true);
    const [filter_type_dragon,   setFilterTypeDragon]   = useState(true);
    const [filter_type_electric, setFilterTypeElectric] = useState(true);
    const [filter_type_fairy,    setFilterTypeFairy]    = useState(true);
    const [filter_type_fighting, setFilterTypeFighting] = useState(true);
    const [filter_type_fire,     setFilterTypeFire]     = useState(true);
    const [filter_type_flying,   setFilterTypeFlying]   = useState(true);
    const [filter_type_ghost,    setFilterTypeGhost]    = useState(true);
    const [filter_type_grass,    setFilterTypeGrass]    = useState(true);
    const [filter_type_ground,   setFilterTypeGround]   = useState(true);
    const [filter_type_ice,      setFilterTypeIce]      = useState(true);
    const [filter_type_normal,   setFilterTypeNormal]   = useState(true);
    const [filter_type_poison,   setFilterTypePoison]   = useState(true);
    const [filter_type_psychic,  setFilterTypePsychic]  = useState(true);
    const [filter_type_rock,     setFilterTypeRock]     = useState(true);
    const [filter_type_steel,    setFilterTypeSteel]    = useState(true);
    const [filter_type_water,    setFilterTypeWater]    = useState(true);

    const [filter_region_alola,  setFilterRegionAlola]  = useState(true);
    const [filter_region_galar,  setFilterRegionGalar]  = useState(true);
    const [filter_region_hisui,  setFilterRegionHisui]  = useState(true);
    const [filter_region_hoenn,  setFilterRegionHoenn]  = useState(true);
    const [filter_region_johto,  setFilterRegionJohto]  = useState(true);
    const [filter_region_kalos,  setFilterRegionKalos]  = useState(true);
    const [filter_region_kanto,  setFilterRegionKanto]  = useState(true);
    const [filter_region_sinnoh, setFilterRegionSinnoh] = useState(true);
    const [filter_region_unys,   setFilterRegionUnys]   = useState(true);

    const { loading, error, data } = useQuery(SESSION_QUERY);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error</p>;

    let isLoggedIn = false;
    if (data.getSession.token) {
       isLoggedIn = true;
    }

    let pokedex;
    if (props.type === 'national') {
        pokedex = 'national';
    } else if (props.type === 'shiny') {
        pokedex = 'shiny';
    }

    function filterOwned (evt) {
        setFilterOwned(!filter_owned);

        let elements = document.getElementsByClassName('captured');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {owned: evt.target.checked});
        }
    };

    function filterNotOwned (evt) {
        setFilterNotOwned(!filter_not_owned);

        let elements = document.getElementsByClassName('not_captured');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {not_owned: evt.target.checked});
        }
    };

    function filterGeneration1 (evt) {
        setFilterGeneration1(!filter_generation_1);

        let elements = document.getElementsByClassName('generation-1');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {generation_1: evt.target.checked});
        }
    };

    function filterGeneration2 (evt) {
        setFilterGeneration2(!filter_generation_2);

        let elements = document.getElementsByClassName('generation-2');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {generation_2: evt.target.checked});
        }
    };

    function filterGeneration3 (evt) {
        setFilterGeneration3(!filter_generation_3);

        let elements = document.getElementsByClassName('generation-3');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {generation_3: evt.target.checked});
        }
    };

    function filterGeneration4 (evt) {
        setFilterGeneration4(!filter_generation_4);

        let elements = document.getElementsByClassName('generation-4');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {generation_4: evt.target.checked});
        }
    };

    function filterGeneration5 (evt) {
        setFilterGeneration5(!filter_generation_5);

        let elements = document.getElementsByClassName('generation-5');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {generation_5: evt.target.checked});
        }
    };

    function filterGeneration6 (evt) {
        setFilterGeneration6(!filter_generation_6);

        let elements = document.getElementsByClassName('generation-6');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {generation_6: evt.target.checked});
        }
    };

    function filterGeneration7 (evt) {
        setFilterGeneration7(!filter_generation_7);

        let elements = document.getElementsByClassName('generation-7');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {generation_7: evt.target.checked});
        }
    };

    function filterGeneration8 (evt) {
        setFilterGeneration8(!filter_generation_8);

        let elements = document.getElementsByClassName('generation-8');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {generation_8: evt.target.checked});
        }
    };

    function filterTypeBug (evt) {
        setFilterTypeBug(!filter_type_bug);

        let elements = document.getElementsByClassName('filter-pokemon-type-bug');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_bug: evt.target.checked});
        }
    };

    function filterTypeDark (evt) {
        setFilterTypeDark(!filter_type_dark);

        let elements = document.getElementsByClassName('filter-pokemon-type-dark');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_dark: evt.target.checked});
        }
    };

    function filterTypeDragon (evt) {
        setFilterTypeDragon(!filter_type_dragon);

        let elements = document.getElementsByClassName('filter-pokemon-type-dragon');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_dragon: evt.target.checked});
        }
    };

    function filterTypeElectric (evt) {
        setFilterTypeElectric(!filter_type_electric);

        let elements = document.getElementsByClassName('filter-pokemon-type-electric');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_electric: evt.target.checked});
        }
    };

    function filterTypeFairy (evt) {
        setFilterTypeFairy(!filter_type_fairy);

        let elements = document.getElementsByClassName('filter-pokemon-type-fairy');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_fairy: evt.target.checked});
        }
    };

    function filterTypeFighting (evt) {
        setFilterTypeFighting(!filter_type_fighting);

        let elements = document.getElementsByClassName('filter-pokemon-type-fighting');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_fighting: evt.target.checked});
        }
    };

    function filterTypeFire (evt) {
        setFilterTypeFire(!filter_type_fire);

        let elements = document.getElementsByClassName('filter-pokemon-type-fire');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_fire: evt.target.checked});
        }
    };

    function filterTypeFlying (evt) {
        setFilterTypeFlying(!filter_type_flying);

        let elements = document.getElementsByClassName('filter-pokemon-type-flying');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_flying: evt.target.checked});
        }
    };

    function filterTypeGhost (evt) {
        setFilterTypeGhost(!filter_type_ghost);

        let elements = document.getElementsByClassName('filter-pokemon-type-ghost');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_ghost: evt.target.checked});
        }
    };

    function filterTypeGrass (evt) {
        setFilterTypeGrass(!filter_type_grass);

        let elements = document.getElementsByClassName('filter-pokemon-type-grass');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_grass: evt.target.checked});
        }
    };

    function filterTypeGround (evt) {
        setFilterTypeGround(!filter_type_ground);

        let elements = document.getElementsByClassName('filter-pokemon-type-ground');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_ground: evt.target.checked});
        }
    };

    function filterTypeIce (evt) {
        setFilterTypeIce(!filter_type_ice);

        let elements = document.getElementsByClassName('filter-pokemon-type-ice');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_ice: evt.target.checked});
        }
    };

    function filterTypeNormal (evt) {
        setFilterTypeNormal(!filter_type_normal);

        let elements = document.getElementsByClassName('filter-pokemon-type-normal');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_normal: evt.target.checked});
        }
    };

    function filterTypePoison (evt) {
        setFilterTypePoison(!filter_type_poison);

        let elements = document.getElementsByClassName('filter-pokemon-type-poison');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_poison: evt.target.checked});
        }
    };

    function filterTypePsychic (evt) {
        setFilterTypePsychic(!filter_type_psychic);

        let elements = document.getElementsByClassName('filter-pokemon-type-psychic');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_psychic: evt.target.checked});
        }
    };

    function filterTypeRock (evt) {
        setFilterTypeRock(!filter_type_rock);

        let elements = document.getElementsByClassName('filter-pokemon-type-rock');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_rock: evt.target.checked});
        }
    };

    function filterTypeSteel (evt) {
        setFilterTypeSteel(!filter_type_steel);

        let elements = document.getElementsByClassName('filter-pokemon-type-steel');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_steel: evt.target.checked});
        }
    };

    function filterTypeWater (evt) {
        setFilterTypeWater(!filter_type_water);

        let elements = document.getElementsByClassName('filter-pokemon-type-water');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {type_water: evt.target.checked});
        }
    };

    function filterRegionAlola (evt) {
        setFilterRegionAlola(!filter_region_alola);

        let elements = document.getElementsByClassName('pokemon-region-alola');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {region_alola: evt.target.checked});
        }
    };

    function filterRegionGalar (evt) {
        setFilterRegionGalar(!filter_region_galar);

        let elements = document.getElementsByClassName('pokemon-region-galar');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {region_galar: evt.target.checked});
        }
    };

    function filterRegionHisui (evt) {
        setFilterRegionHisui(!filter_region_hisui);

        let elements = document.getElementsByClassName('pokemon-region-hisui');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {region_hisui: evt.target.checked});
        }
    };

    function filterRegionHoenn (evt) {
        setFilterRegionHoenn(!filter_region_hoenn);

        let elements = document.getElementsByClassName('pokemon-region-hoenn');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {region_hoenn: evt.target.checked});
        }
    };

    function filterRegionJohto (evt) {
        setFilterRegionJohto(!filter_region_johto);

        let elements = document.getElementsByClassName('pokemon-region-johto');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {region_johto: evt.target.checked});
        }
    };

    function filterRegionKalos (evt) {
        setFilterRegionKalos(!filter_region_kalos);

        let elements = document.getElementsByClassName('pokemon-region-kalos');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {region_kalos: evt.target.checked});
        }
    };

    function filterRegionKanto (evt) {
        setFilterRegionKanto(!filter_region_kanto);

        let elements = document.getElementsByClassName('pokemon-region-kanto');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {region_kanto: evt.target.checked});
        }
    };

    function filterRegionSinnoh (evt) {
        setFilterRegionSinnoh(!filter_region_sinnoh);

        let elements = document.getElementsByClassName('pokemon-region-sinnoh');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {region_sinnoh: evt.target.checked});
        }
    };

    function filterRegionUnys (evt) {
        setFilterRegionUnys(!filter_region_unys);

        let elements = document.getElementsByClassName('pokemon-region-unys');
        for(let i=0, len=elements.length; i<len; i++) {
            mainFilterElement(elements[i], {region_unys: evt.target.checked});
        }
    };

    function mainFilterElement(element, filter) {
        /*
         *  GENERATION
         */
        let generation_valid = false;

        let generation_1 = ( 'generation_1' in filter ? filter.generation_1 : filter_generation_1 );
        if (element.classList.contains("generation-1") && generation_1) {
            generation_valid = true;
        }

        let generation_2 = ( 'generation_2' in filter ? filter.generation_2 : filter_generation_2 );
        if (element.classList.contains("generation-2") && generation_2) {
            generation_valid = true;
        }

        let generation_3 = ( 'generation_3' in filter ? filter.generation_3 : filter_generation_3 );
        if (element.classList.contains("generation-3") && generation_3) {
            generation_valid = true;
        }

        let generation_4 = ( 'generation_4' in filter ? filter.generation_4 : filter_generation_4 );
        if (element.classList.contains("generation-4") && generation_4) {
            generation_valid = true;
        }

        let generation_5 = ( 'generation_5' in filter ? filter.generation_5 : filter_generation_5 );
        if (element.classList.contains("generation-5") && generation_5) {
            generation_valid = true;
        }

        let generation_6 = ( 'generation_6' in filter ? filter.generation_6 : filter_generation_6 );
        if (element.classList.contains("generation-6") && generation_6) {
            generation_valid = true;
        }

        let generation_7 = ( 'generation_7' in filter ? filter.generation_7 : filter_generation_7 );
        if (element.classList.contains("generation-7") && generation_7) {
            generation_valid = true;
        }

        let generation_8 = ( 'generation_8' in filter ? filter.generation_8 : filter_generation_8 );
        if (element.classList.contains("generation-8") && generation_8) {
            generation_valid = true;
        }

        /*
         *  TYPE
         */
        let type_valid = false;

        let type_bug = ( 'type_bug' in filter ? filter.type_bug : filter_type_bug );
        if (element.classList.contains("filter-pokemon-type-bug") && type_bug) {
            type_valid = true;
        }

        let type_dark = ( 'type_dark' in filter ? filter.type_dark : filter_type_dark );
        if (element.classList.contains("filter-pokemon-type-dark") && type_dark) {
            type_valid = true;
        }

        let type_dragon = ( 'type_dragon' in filter ? filter.type_dragon : filter_type_dragon );
        if (element.classList.contains("filter-pokemon-type-dragon") && type_dragon) {
            type_valid = true;
        }

        let type_electric = ( 'type_electric' in filter ? filter.type_electric : filter_type_electric );
        if (element.classList.contains("filter-pokemon-type-electric") && type_electric) {
            type_valid = true;
        }

        let type_fairy = ( 'type_fairy' in filter ? filter.type_fairy : filter_type_fairy );
        if (element.classList.contains("filter-pokemon-type-fairy") && type_fairy) {
            type_valid = true;
        }

        let type_fighting = ( 'type_fighting' in filter ? filter.type_fighting : filter_type_fighting );
        if (element.classList.contains("filter-pokemon-type-fighting") && type_fighting) {
            type_valid = true;
        }

        let type_fire = ( 'type_fire' in filter ? filter.type_fire : filter_type_fire );
        if (element.classList.contains("filter-pokemon-type-fire") && type_fire) {
            type_valid = true;
        }

        let type_flying = ( 'type_flying' in filter ? filter.type_flying : filter_type_flying );
        if (element.classList.contains("filter-pokemon-type-flying") && type_flying) {
            type_valid = true;
        }

        let type_ghost = ( 'type_ghost' in filter ? filter.type_ghost : filter_type_ghost );
        if (element.classList.contains("filter-pokemon-type-ghost") && type_ghost) {
            type_valid = true;
        }

        let type_grass = ( 'type_grass' in filter ? filter.type_grass : filter_type_grass );
        if (element.classList.contains("filter-pokemon-type-grass") && type_grass) {
            type_valid = true;
        }

        let type_ground = ( 'type_ground' in filter ? filter.type_ground : filter_type_ground );
        if (element.classList.contains("filter-pokemon-type-ground") && type_ground) {
            type_valid = true;
        }

        let type_ice = ( 'type_ice' in filter ? filter.type_ice : filter_type_ice );
        if (element.classList.contains("filter-pokemon-type-ice") && type_ice) {
            type_valid = true;
        }

        let type_normal = ( 'type_normal' in filter ? filter.type_normal : filter_type_normal );
        if (element.classList.contains("filter-pokemon-type-normal") && type_normal) {
            type_valid = true;
        }

        let type_poison = ( 'type_poison' in filter ? filter.type_poison : filter_type_poison );
        if (element.classList.contains("filter-pokemon-type-poison") && type_poison) {
            type_valid = true;
        }

        let type_psychic = ( 'type_psychic' in filter ? filter.type_psychic : filter_type_psychic );
        if (element.classList.contains("filter-pokemon-type-psychic") && type_psychic) {
            type_valid = true;
        }

        let type_rock = ( 'type_rock' in filter ? filter.type_rock : filter_type_rock );
        if (element.classList.contains("filter-pokemon-type-rock") && type_rock) {
            type_valid = true;
        }

        let type_steel = ( 'type_steel' in filter ? filter.type_steel : filter_type_steel );
        if (element.classList.contains("filter-pokemon-type-steel") && type_steel) {
            type_valid = true;
        }

        let type_water = ( 'type_water' in filter ? filter.type_water : filter_type_water );
        if (element.classList.contains("filter-pokemon-type-water") && type_water) {
            type_valid = true;
        }

        /*
         *  REGION
         */
        let region_valid = false;

        let alola = ( 'region_alola' in filter ? filter.region_alola : filter_region_alola );
        if (element.classList.contains("pokemon-region-alola") && alola) {
            region_valid = true;
        }

        let galar = ( 'region_galar' in filter ? filter.region_galar : filter_region_galar );
        if (element.classList.contains("pokemon-region-galar") && galar) {
            region_valid = true;
        }

        let hisui = ( 'region_hisui' in filter ? filter.region_hisui : filter_region_hisui );
        if (element.classList.contains("pokemon-region-hisui") && hisui) {
            region_valid = true;
        }

        let hoenn = ( 'region_hoenn' in filter ? filter.region_hoenn : filter_region_hoenn );
        if (element.classList.contains("pokemon-region-hoenn") && hoenn) {
            region_valid = true;
        }

        let johto = ( 'region_johto' in filter ? filter.region_johto : filter_region_johto );
        if (element.classList.contains("pokemon-region-johto") && johto) {
            region_valid = true;
        }

        let kalos = ( 'region_kalos' in filter ? filter.region_kalos : filter_region_kalos );
        if (element.classList.contains("pokemon-region-kalos") && kalos) {
            region_valid = true;
        }

        let kanto = ( 'region_kanto' in filter ? filter.region_kanto : filter_region_kanto );
        if (element.classList.contains("pokemon-region-kanto") && kanto) {
            region_valid = true;
        }

        let sinnoh = ( 'region_sinnoh' in filter ? filter.region_sinnoh : filter_region_sinnoh );
        if (element.classList.contains("pokemon-region-sinnoh") && sinnoh) {
            region_valid = true;
        }

        let unys = ( 'region_unys' in filter ? filter.region_unys : filter_region_unys );
        if (element.classList.contains("pokemon-region-unys") && unys) {
            region_valid = true;
        }

        /*  */
        let display = 'none';
        if (generation_valid && type_valid && region_valid) {
            display = 'block';
        }

        /*
         *  OWNED
         */
        let owned = ( 'owned' in filter ? filter.owned : filter_owned );
        if (element.classList.contains("captured") && !owned) {
            display = 'none';
        }

        let not_owned = ( 'not_owned' in filter ? filter.not_owned : filter_not_owned );
        if (element.classList.contains("not_captured") && !not_owned) {
            display = 'none';
        }

        element.style["display"] = display;
    };

    return (
        <div className="Sidebar">
            <div className="wrapper d-flex align-items-stretch">
                <nav id="sidebar">
                    <div className="custom-menu">
                        <button type="button" id="sidebarCollapse" className="btn shinyButton">
                            <i className="fa fa-bars"></i>
                            <span className="sr-only">Toggle Menu</span>
                        </button>
                    </div>
                    <div className="p-4 pt-5">
                        <h1><a href="/" className="logo">ShinyDex</a></h1>
                        <div className="pokebar">
                            <img src="/images/barre.png" alt="poke-bar" />
                        </div>
                        <ul className="list-unstyled components mb-5">
                            <li>
                                <input type="text" className="form-control" placeholder="Rechercher"/>
                            </li>
                            <li>
                                <div className="pokebar">
                                    <img src="/images/barre.png" alt="poke-bar" />
                                </div>
                            </li>
                            <li>
                                <Link to="/national">
                                    National
                                </Link>
                            </li>
                            <li>
                                <Link to="/shiny">
                                    Shiny
                                </Link>
                            </li>
                            <li>
                                <div className="pokebar">
                                    <img src="/images/barre.png" alt="poke-bar" />
                                </div>
                            </li>
                            <li>
                                <label>
                                    <input type="checkbox" checked={filter_owned} onChange={filterOwned} /> Possédé
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="checkbox" checked={filter_not_owned} onChange={filterNotOwned} /> Non Possédé
                                </label>
                            </li>
                            <li>
                                <a href="#generation-filters" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                    Générations
                                </a>
                                <ul className="list-unstyled collapse" id="generation-filters">
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_generation_1} onChange={filterGeneration1} />
                                            Generation 1
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_generation_2} onChange={filterGeneration2} />
                                            Generation 2
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_generation_3} onChange={filterGeneration3} />
                                            Generation 3
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_generation_4} onChange={filterGeneration4} />
                                            Generation 4
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_generation_5} onChange={filterGeneration5} />
                                            Generation 5
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_generation_6} onChange={filterGeneration6} />
                                            Generation 6
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_generation_7} onChange={filterGeneration7} />
                                            Generation 7
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_generation_8} onChange={filterGeneration8} />
                                            Generation 8
                                        </label>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#type-filters" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                    Types
                                </a>
                                <ul className="list-unstyled collapse" id="type-filters">
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_steel} onChange={filterTypeSteel} />
                                            Acier
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_fighting} onChange={filterTypeFighting} />
                                            Combat
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_dragon} onChange={filterTypeDragon} />
                                            Dragon
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_water} onChange={filterTypeWater} />
                                            Eau
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_electric} onChange={filterTypeElectric} />
                                            Elektric
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_fairy} onChange={filterTypeFairy} />
                                            Fée
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_fire} onChange={filterTypeFire} />
                                            Feu
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_ice} onChange={filterTypeIce} />
                                            Glace
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_bug} onChange={filterTypeBug} />
                                            Insecte
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_normal} onChange={filterTypeNormal} />
                                            Normal
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_grass} onChange={filterTypeGrass} />
                                            Plante
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_poison} onChange={filterTypePoison} />
                                            Poison
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_psychic} onChange={filterTypePsychic} />
                                            Psy
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_rock} onChange={filterTypeRock} />
                                            Roche
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_ground} onChange={filterTypeGround} />
                                            Sol
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_ghost} onChange={filterTypeGhost} />
                                            Spectre
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_dark} onChange={filterTypeDark} />
                                            Ténèbres
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_type_flying} onChange={filterTypeFlying} />
                                            Vol
                                        </label>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#region-filters" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                    Regions
                                </a>
                                <ul className="list-unstyled collapse" id="region-filters">
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_region_alola} onChange={filterRegionAlola} />
                                            Alola
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_region_galar} onChange={filterRegionGalar} />
                                            Galar
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_region_hisui} onChange={filterRegionHisui} />
                                            Hisui
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_region_hoenn} onChange={filterRegionHoenn} />
                                            Hoenn
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_region_johto} onChange={filterRegionJohto} />
                                            Johto
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_region_kalos} onChange={filterRegionKalos} />
                                            Kalos
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_region_kanto} onChange={filterRegionKanto} />
                                            Kanto
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_region_sinnoh} onChange={filterRegionSinnoh} />
                                            Sinnoh
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={filter_region_unys} onChange={filterRegionUnys} />
                                            Unys
                                        </label>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div className="pokebar">
                                    <img src="/images/barre.png" alt="poke-bar" />
                                </div>
                            </li>
                            <li>
                                <a href={`${process.env.REACT_APP_API}/admin/login`}> Connecter </a>
                                <em> { data.getSession.token } </em>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div id="content" className="p-4 p-md-5 pt-5">
                    <AddCapture isLoggedIn={isLoggedIn} {...props} />
                    <h2 className="mb-4 poke-title">Rukia's ShinyDex</h2>
                    <div className="progress">
                        <div className="progress-bar poke-progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                    <AddPokemon isLoggedIn={isLoggedIn} {...props} />
                    <ListPokemon isLoggedIn={isLoggedIn} {...props} pokedex_test={pokedex} />
                </div>
            </div>
            <Helmet>
                <script src="/js/main.js"></script>
            </Helmet>
        </div>
      );
}

export default Sidebar;
