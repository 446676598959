import './App.css';
import Sidebar from './Components/Sidebar';
import Session from './Components/Session';
import { ReactSession } from 'react-client-session';
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

ReactSession.setStoreType("localStorage");
const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_API,
    cache: new InMemoryCache(),
    headers: {
        'Authorization': ReactSession.get('token'),
        'Client-Name': 'shinydex'
    }
});

// optional configuration
const alert_options = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_RIGHT,
    timeout: 5000,
    offset: '10px',
    // you can also just use 'scale'
    transition: transitions.SCALE
}

function App() {
    return (
        <BrowserRouter>
            <AlertProvider template={AlertTemplate} {...alert_options}>
                <div className="App">
                    <ApolloProvider client={client}>
                        <Routes>
                            <Route path="/session"  element={<Session />} />
                            <Route path="/"         element={<Sidebar type="national" />} timestamp={ new Date().toString() } />
                            <Route path="/national" element={<Sidebar type="national" />} timestamp={ new Date().toString() } />
                            <Route path="/shiny"    element={<Sidebar type="shiny"    />} timestamp={ new Date().toString() } />
                        </Routes>
                    </ApolloProvider>
                </div>
            </AlertProvider>
        </BrowserRouter>
    );
}

export default App;
