import React from 'react';
import { ReactSession } from 'react-client-session';
import { useSearchParams, Navigate } from 'react-router-dom';

function Session() {
    const [searchParams] = useSearchParams();
    let token = searchParams.get('token');

    ReactSession.setStoreType("localStorage");
    ReactSession.set("token", token);

    console.log("token", token)

    return (
        <Navigate to="/" />
    );
}

export default Session;
