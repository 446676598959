import './PokemonDetails.css';
import Moment from 'moment';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useAlert } from "react-alert";

function PokemonDetails(props) {

    let main_class_not_captured = `pokemon not_captured generation-${props.pokemon.generation}`;
    let main_class_captured     = `pokemon captured generation-${props.pokemon.generation}`;

    let image_class_captured     = `pokemon-image pokemon-captured`;
    let image_class_not_captured = `pokemon-image pokemon-not-captured`;

    let image_type_class_not_captured = `pokemon-type pokemon-type-none`;
    let image_type_class_captured     = `pokemon-type pokemon-type-${props.pokemon.type}`;

    let regions_class = 'pokemon-region-'+(props.pokemon.regions.split(',').join(' pokemon-region-'));

    const pokeAlert = useAlert();

    const [display_game,      setGame]           = useState("");
    const [display_date,      setDate]           = useState("");
    const [display_method,    setMethod]         = useState("");
    const [display_encounter, setEncounter]      = useState("");
    const [captured,          setCaptured]       = useState(() => initIsCaptured());
    const [main_class,        setMainClass]      = useState(() => initMainClass());
    const [image_class,       setImageClass]     = useState(() => initImageClass());
    const [image_type_class,  setImageTypeClass] = useState(() => initImageTypeClass());
    const [type_class]      = useState(() => initTypeClass());

    function initMainClass() {
        return props.capture.length > 0 ? main_class_captured : main_class_not_captured;
    }

    function initImageClass() {
        return props.capture.length > 0 ? image_class_captured : image_class_not_captured;
    }

    function initImageTypeClass() {
        return props.capture.length > 0 ? image_type_class_captured : image_type_class_not_captured;
    }

    function initTypeClass() {
        let types_class = props.pokemon.type.split('_');
        types_class = types_class.join(' filter-pokemon-type-');
        types_class = 'filter-pokemon-type-' + types_class;
        return types_class;
    }

    function initIsCaptured() {
        let captured = false;
        if (props.capture.length > 0) {
            captured = true;
        }

        return captured;
    }

    function setPokemonDetails(pokedex_number, game_name, date, method, encounter) {
        setGame(game_name);
        setDate(date);
        setMethod(method);
        setEncounter(encounter);

        let elements = document.getElementsByClassName('pokemon-details');
        for(var i=0, len=elements.length; i<len; i++)
        {
            elements[i].style["display"] = 'none';
        }

        let elements_selected = document.getElementsByClassName('pokemon-details-'+pokedex_number);
        for(i=0, len=elements_selected.length; i<len; i++)
        {
            elements_selected[i].style["display"] = 'block';
        }
    }

    function toggleCapture(evt) {
        if (!props.isLoggedIn || props.type !== 'national') {
            return;
        }

        let pokemon_id     = evt.target.getAttribute('data-pokemon-id');

        setCaptured(!captured);

        if (captured){
            // set free
            setMainClass(main_class_not_captured);
            setImageClass(image_class_not_captured);
            setImageTypeClass(image_type_class_not_captured);

            axios.delete(process.env.REACT_APP_API+`/api/captures`, {
                headers: {
                    'Authorization': ReactSession.get('token'),
                    'Client-Name': 'shinydex'
                },
                data: {
                    pokemon_id  : parseInt(pokemon_id),
                }
            })
            .then(res => {
                console.log(res);
                console.log(res.data);
                pokeAlert.show("Pokemon libéré");
            })
            .catch(function(err) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops ...',
                    text: err.response.data.message,
                });
            })
        } else {
            // set captured
            setMainClass(main_class_captured);
            setImageClass(image_class_captured);
            setImageTypeClass(image_type_class_captured);

            let capture = {
                pokemon_id  : parseInt(pokemon_id),
                shiny       : false,
                catch_at    : Moment(new Date()).format("YYYY-MM-DD"),
                gender      : 'genderless',
            };

            axios.post(process.env.REACT_APP_API+`/api/captures`, { capture }, {
                headers: {
                    'Authorization': ReactSession.get('token'),
                    'Client-Name': 'shinydex'
                }
            })
            .then(res => {
                console.log(res);
                console.log(res.data);
                pokeAlert.success("Pokémon capturé");
            })
            .catch(function(err) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops ...',
                    text: err.response.data.message,
                });
            })
        }
    }

    Moment.locale('fr');

    let pokemonImage = <img className={image_class}
            src={`/images/pokemons/default/${props.pokemon.pokedex_number}.png`}
            alt="pokemon"
            data-pokemon-id={props.pokemon.ID}
            data-captured={captured}
            onClick={toggleCapture}
            loading="lazy"
        />;

    if (props.type === 'shiny') {
        pokemonImage = <img className={image_class}
            src={`/images/pokemons/shiny/${props.pokemon.pokedex_number}.png`}
            alt="pokemon"
            data-pokemon-id={props.pokemon.ID}
            data-captured={captured}
            onClick={toggleCapture}
            loading="lazy"
        />;
    }

    return (
        <div className={`${main_class} ${type_class} ${regions_class}`}>
            <div className={image_type_class}>
                <div className="pokemon-name">
                    #{props.pokemon.pokedex_number} {props.pokemon.name_fr}
                </div>
                {pokemonImage}
                <div className="pokemon-balls">
                    {props.capture.map(function(capture, idx){
                        if (capture.pokeball.slug !== '') {
                            return (
                                <img className={`pokeball-image`} src={`/images/pokeballs/${capture.pokeball.slug}.png`} alt="pokeball"
                                    loading="lazy"
                                    onClick={() => setPokemonDetails(
                                        props.pokemon.pokedex_number,
                                        capture.game.name_fr,
                                        capture.catch_at,
                                        capture.method.name_fr,
                                        capture.encounter
                                    )}
                                />
                            )
                        }
                    })}
                </div>
            </div>
            <div className={`pokemon-details pokemon-details-${props.pokemon.pokedex_number}`}>
                <div className="details_label">Jeu</div>
                <div className="details_value">{ display_game }</div>
                <div className="details_label">Date</div>
                <div className="details_value">{ Moment(display_date).format('d MMM Y') }</div>
                <div className="details_label">Méthode</div>
                <div className="details_value">{ display_method }</div>
                <div className="details_label">Encounter</div>
                <div className="details_value">{ display_encounter }</div>
            </div>
        </div>
    );
}

export default PokemonDetails;
