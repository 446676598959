import './AddPokemon.css';
import React from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { useQuery, gql } from "@apollo/client";

const POKEMONS_QUERY = gql`
    {
        pokemons {
            ID
            name_fr
            name_en
            pokedex_number
            slug
        }
    }
`;

const POKEBALLS_QUERY = gql`
    {
        pokeballs {
            ID
            name_fr
            name_en
            slug
        }
    }
`;

const METHODS_QUERY = gql`
    {
        methods {
            ID
            name_fr
            name_en
            slug
        }
    }
`;

const GAMES_QUERY = gql`
    {
        games {
            ID
            name_fr
            name_en
            slug
        }
    }
`;

function AddPokemon(props) {

    const { loading: loadingPokemons,  error: errorPokemons,  data: pokemons }  = useQuery(POKEMONS_QUERY);
    const { loading: loadingPokeballs, error: errorPokeballs, data: pokeballs } = useQuery(POKEBALLS_QUERY);
    const { loading: loadingMethods,   error: errorMethods,   data: methods }   = useQuery(METHODS_QUERY);
    const { loading: loadingGames,     error: errorGames,     data: games }     = useQuery(GAMES_QUERY);

    if (loadingPokemons)  return <p>Loading Pokemons...</p>;
    if (loadingPokeballs) return <p>Loading Pokeballs...</p>;
    if (loadingMethods)   return <p>Loading Methods...</p>;
    if (loadingGames)     return <p>Loading Games...</p>;
    if (errorPokemons)    return <p>Error pokemons</p>;
    if (errorPokeballs)   return <p>Error pokeballs</p>;
    if (errorMethods)     return <p>Error methods</p>;
    if (errorGames)       return <p>Error games</p>;

    let optionPokemons = [];
    pokemons.pokemons.forEach( element => optionPokemons.push({
        value: element.ID,
        label: '#'+ element.pokedex_number + ' ' +  element.name_fr + ' (' + element.name_en + ')'
    }));

    let optionPokeballs = [];
    pokeballs.pokeballs.forEach( element => optionPokeballs.push({
        value: element.ID,
        label: element.name_fr + ' (' + element.name_en + ')'
    }));

    let optionMethods = [];
    methods.methods.forEach( element => optionMethods.push({
        value: element.ID,
        label: element.name_fr + ' (' + element.name_en + ')'
    }));

    let optionGames = [];
    games.games.forEach( element => optionGames.push({
        value: element.ID,
        label: element.name_fr + ' (' + element.name_en + ')'
    }));

    let capture = {
        pokemon_id  : undefined,
        game_id     : undefined,
        method_id   : undefined,
        pokeball_id : undefined,
        catch_at    : undefined,
        encounter   : 0,
        gender      : 'genderless',
        shiny       : true,
    };

    function handlePokemonChange (selectedOption) {
        capture.pokemon_id = selectedOption.value;
    };

    function handlePokeballChange (selectedOption) {
        capture.pokeball_id = selectedOption.value;
    };

    function handleGameChange (selectedOption) {
        capture.game_id = selectedOption.value;
    };

    function handleMethodChange (selectedOption) {
        capture.method_id = selectedOption.value;
    };

    function handleDateChange (selectedOption) {
        capture.catch_at = selectedOption.target.value;
    };

    function handleEncounterChange (selectedOption) {
        let encounter = selectedOption.target.value;
        encounter = parseInt(encounter, 10);
        capture.encounter = encounter;
    };

    function handleGenderChange (selectedOption) {
        capture.gender = selectedOption.target.value;
    };

    function handleShinyChange (selectedOption) {
        if (selectedOption.target.value === 'on') {
            capture.shiny = true;
        } else {
            capture.shiny = false;
        }
    };

    function addCapture() {
        console.log("addCapture()", capture);
        axios.post(process.env.REACT_APP_API+`/api/captures`, { capture }, {
            headers: {
                'Authorization': ReactSession.get('token'),
                'Client-Name': 'shinydex'
            }
        })
        .then(res => {
            console.log(res);
            console.log(res.data);
            Swal.fire({
                title: 'Success',
                text: 'Capture ajouté',
            });
        })
        .catch(function(err) {
            Swal.fire({
                icon: 'error',
                title: 'Oops ...',
                text: err.response.data.message,
            });
        })
    }

    if (props.isLoggedIn) {
        return (
            <div className="AddPokemon">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group row">
                            <label htmlFor="inputPokemon" className="col-sm-2 col-form-label">Pokemon :</label>
                            <div className="col-sm-4">
                                <Select
                                    options={optionPokemons}
                                    onChange={handlePokemonChange}
                                />
                            </div>
                            <label htmlFor="inputGame" className="col-sm-2 col-form-label">Jeu :</label>
                            <div className="col-sm-4">
                                <Select
                                    options={optionGames}
                                    onChange={handleGameChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputPokeball" className="col-sm-2 col-form-label">Pokeball :</label>
                            <div className="col-sm-4">
                                <Select
                                    options={optionPokeballs}
                                    onChange={handlePokeballChange}
                                />
                            </div>
                            <label htmlFor="inputMode" className="col-sm-2 col-form-label">Methode :</label>
                            <div className="col-sm-4">
                                <Select
                                    options={optionMethods}
                                    onChange={handleMethodChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputDate" className="col-sm-2 col-form-label">Date :</label>
                            <div className="col-sm-4">
                                <input type="date" className="form-control" id="inputDate" placeholder="Date"
                                    onChange={handleDateChange}
                                />
                            </div>
                            <label htmlFor="inputEncounter" className="col-sm-2 col-form-label">Encounter :</label>
                            <div className="col-sm-4">
                                <input type="number" className="form-control" id="inputEncounter" placeholder="Encounter"
                                    onChange={handleEncounterChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Sexe :</label>
                            <div className="col-sm-4" onChange={handleGenderChange}>
                                <label><input type="radio" value="male"       name="gender"/> Male</label>
                                <label><input type="radio" value="female"     name="gender"/> Female</label>
                                <label><input type="radio" value="genderless" name="gender" defaultChecked /> Other</label>
                            </div>
                            <div className="col-sm-4 cbx-label-hidden">
                                <label>
                                    <input
                                        type="checkbox"
                                        onChange={handleShinyChange}
                                        className="cbx"
                                        checked="checked"
                                    /> Shiny
                                </label>
                            </div>
                        </div>
                        <button type="submit" onClick={addCapture} className="btn shinyButton">Ajouter</button>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
            </div>
        );
    }
}

export default AddPokemon;
