import './AddPokemon.css';
import React from 'react';

function AddCapture(props) {

    function displayAddForm (evt) {
        let elements = document.getElementsByClassName('AddPokemon');
        for(let i=0, len=elements.length; i<len; i++) {
            if (elements[i].style["display"] !== 'block') {
                elements[i].style["display"] = 'block';
            } else {
                elements[i].style["display"] = 'none';
            }
        }
    };

    if (props.isLoggedIn && props.type === 'shiny') {
        return (
            <div className="AddCapture">
               <button type="button" id="addShinyCapture" className="btn addShinyCapture shinyButton" onClick={displayAddForm}>
                    Add Capture
                </button>
            </div>
        );
    } else {
        return (
            <div>
            </div>
        );
    }
}

export default AddCapture;
