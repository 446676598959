import './ListPokemon.css';
import React from 'react';
import PokemonDetails from './PokemonDetails';
import { useQuery, gql } from "@apollo/client";

const CAPTURES_QUERY = gql`
    {
        captures(shiny: false) {
            catch_at
            encounter
            pokemon {
                ID
                slug
                name_fr
                pokedex_number
                type
                generation
            }
            pokeball {
                slug
                name_fr
            }
            game {
                slug
                name_fr
            }
            method {
                slug
                name_fr
            }
        }
    }
`;

const CAPTURES_SHINY_QUERY = gql`
    {
        captures(shiny: true) {
            catch_at
            encounter
            pokemon {
                ID
                slug
                name_fr
                pokedex_number
                type
                generation
            }
            pokeball {
                slug
                name_fr
            }
            game {
                slug
                name_fr
            }
            method {
                slug
                name_fr
            }
        }
    }
`;

const POKEMONS_QUERY = gql`
    {
        pokemons {
            slug
            name_fr
            pokedex_number
            type
            generation
            regions
            ID
        }
    }
`;

function getCaptures(type) {
    if (type === 'shiny') {
        return CAPTURES_SHINY_QUERY;
    } else if ( type === 'national') {
        return CAPTURES_QUERY;
    }
}

function ListPokemon(props) {

    const { loading: loadingCaptures,  error: errorCaptures,  data: captures }  = useQuery(getCaptures(props.pokedex_test));
    const { loading: loadingPokemons,  error: errorPokemons,  data: pokemons }  = useQuery(POKEMONS_QUERY);

    if (loadingCaptures)  return <p>Loading captures...</p>;
    if (loadingPokemons)  return <p>Loading pokemons...</p>;
    if (errorCaptures)    return <p>Error captures</p>;
    if (errorPokemons)    return <p>Error pokemons</p>;

    let pokemonList = [];
    captures.captures.forEach( function(element){
        if (!(element.pokemon.pokedex_number in pokemonList)) {
            pokemonList[element.pokemon.pokedex_number] = [];
        }
        pokemonList[element.pokemon.pokedex_number].push({
            id        : element.id,
            catch_at  : element.catch_at,
            pokemon   : element.pokemon,
            pokeball  : element.pokeball,
            game      : element.game,
            method    : element.method,
            encounter : element.encounter,
        });
    })

    return (
        <div className="ListPokemon">
            {pokemons.pokemons.map(function(pokemon, idx){
                let pokemons_captured = [];
                if (pokemon.pokedex_number in pokemonList) {
                    pokemons_captured = pokemonList[pokemon.pokedex_number];
                }
                return (
                    <PokemonDetails key={pokemon.pokedex_number} pokemon={pokemon} capture={pokemons_captured} {...props} />
                )
            })}
        </div>
    );
}

export default ListPokemon;
